import { useCallback, useEffect, useState } from "react"


type CopyStatus = undefined | '✔️'|'🔴'
export const useCopyToClipboard = (text: string, notifyTimeout = 2500): [CopyStatus, () => void] => {
    const [copyStatus, setCopyStatus] = useState<CopyStatus>(undefined)
    const copy = useCallback(() => {
      navigator.clipboard.writeText(text).then(
        () => setCopyStatus('✔️'),
        () => setCopyStatus('🔴'),
      )
    }, [text])
  
    useEffect(() => {
      if (copyStatus === undefined) {
        return
      }
  
      const timeoutId = setTimeout(() => setCopyStatus(undefined), notifyTimeout)
  
      return () => clearTimeout(timeoutId)
    }, [copyStatus])
  
    return [copyStatus, copy]
  }