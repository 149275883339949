import { FC } from "react"
import { useCopyToClipboard } from "../utils/useCopyToClipboard"

export const CopyButton: FC<{text: string}> = ({ text }) => {
    const [copyStatus, copy] = useCopyToClipboard(text)
  
  
    return <button onClick={(e) => {
        e.preventDefault();
        copy()
    }}>{copyStatus || '📋'}</button>
  }